import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
const api = axios.create({
    baseURL: 'https://pfi01gkkd9.execute-api.eu-north-1.amazonaws.com/default/',
  });
export const postEmailList = (payload, success) =>{
    api
    .post("updatePrelaunchEmailList", payload)
    .then((response) => {
      toast.success("Welcome aboard! Stay up to date ");
      success()
    })
    .catch((error) => {
      toast.error("Something went wrong");
      success()
    });
}