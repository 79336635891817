import React, { useState, Fragment } from "react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  ClipboardDocumentCheckIcon,
  GiftIcon,
  ClockIcon,
  XMarkIcon,
  BoltIcon,
  UserGroupIcon,
  ClipboardIcon,
  ChartBarIcon,
  // Gri,
} from "@heroicons/react/24/outline";
export default function FeatureSection() {
  return (
    <section className="pt-20 pb-12 pt-12  lg:pt-[120px] lg:pb-[90px] ">
      <div className="mx-auto max-w-screen-xl py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              <span className="text-primary text-indigo-600 mb-2 block text-lg font-semibold">
                Our Services
              </span>
              <h2 className="text-dark mb-4 text-3xl font-bold sm:text-4xl md:text-[40px]">
                What We Offer
              </h2>
              <p className="text-body-color text-base">
                Our feature-rich platform offers seamless integration and
                user-friendly interfaces to help you manage your shop
                efficiently and effectively
              </p>  
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap  mx-auto max-w-screen-xl lg:px-6">
          <div className="w-full lg:px-4   md:px-4   md:w-1/2 lg:w-1/2">
            <div
              style={{ background: "#00AC6B" }}
              className="mb-8   text-white rounded-[20px] bg-white p-10 md:px-7 xl:px-10 hover:shadow-lg shadow-pricing relative z-10 mb-10 overflow-hidden rounded-xl border border-opacity-20 bg-white "
            >
              <div className=" black-color bg-primary mb-8 flex h-[70px] w-[70px] items-center rounded-full bg-primary-100 justify-center rounded-2xl">
                <UserGroupIcon
                  className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <h4 className=" mb-3 text-xl font-semibold">
                Streamline employee management
              </h4>
              <p className="text-body-color">
                Efficiently manage employee onboarding, and
                exit procedures through a single platform.
              </p>
            </div>
          </div>
          <div className="w-full lg:px-4 md:px-4 md:w-1/2 lg:w-1/2">
            <div
              style={{ background: "#519AF4" }}
              className=" text-white mb-8 rounded-[20px] bg-white p-10 md:px-7 xl:px-10 hover:shadow-lg shadow-pricing relative z-10 mb-10 overflow-hidden rounded-xl border border-opacity-20 bg-white "
            >
              <div className=" black-color bg-primary mb-8 flex h-[70px] w-[70px] items-center rounded-full bg-primary-100 justify-center rounded-2xl">
                <ClipboardDocumentCheckIcon
                  className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <h4 className=" mb-3 text-xl font-semibold">
                Operations Management
              </h4>
              <p className="text-body-color">
                Streamline management of tables, menu and ensure accuracy and
                timely delivery of orders and services.
              </p>
            </div>
          </div>
          <div className="w-full lg:px-4 md:px-4 md:w-1/2 lg:w-1/2">
            <div
              style={{ background: "#1C4396" }}
              className=" text-white mb-8 rounded-[20px] bg-white p-10 md:px-7 xl:px-10 hover:shadow-lg shadow-pricing relative z-10 mb-10 overflow-hidden rounded-xl border border-opacity-20 bg-white "
            >
              <div className=" black-color bg-primary mb-8 flex h-[70px] w-[70px] items-center rounded-full bg-primary-100 justify-center rounded-2xl">
                <ChartBarIcon
                  className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <h4 className="mb-3 text-xl font-semibold">
                Insights and Analytics
              </h4>
              <p className="text-body-color">
                Gain clear, actionable insights into your business performance
                with basic analytics.
              </p>
            </div>
          </div>
          <div className="w-full lg:px-4 md:px-4 md:w-1/2 lg:w-1/2">
            <div
              style={{ background: "#F78400" }}
              className=" text-white mb-8 rounded-[20px] bg-white p-10 md:px-7 xl:px-10 hover:shadow-lg shadow-pricing relative z-10 mb-10 overflow-hidden rounded-xl border border-opacity-20 bg-white "
            >
              <div className=" black-color bg-primary mb-8 flex h-[70px] w-[70px] items-center rounded-full bg-primary-100 justify-center rounded-2xl">
                <BoltIcon
                  className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <h4 className=" mb-3 text-xl font-semibold">
                Comprehensive Overview
              </h4>
              <p className="text-body-color">
                Maintain a centralized control panel for all features, providing
                a seamless and efficient way to oversee your business
                operations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
