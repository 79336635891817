import React, { useState, Fragment } from "react";
import toast, { Toaster } from "react-hot-toast";
import CtaButton from "./cta-button";
export default function NewsLetter() {
  const notify = () => toast.success("Successfully toasted!");
  return (
    <section
      style={{ background: " #0353A7" }}
      className="bg-white dark:bg-gray-900"
    >
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md sm:text-center">
          <h2 className="mb-4 text-3xl tracking-tight font-bold text-white sm:text-4xl dark:text-white">
            Unlock Exclusive Insights and Benefits with us
          </h2>
          <p className="mx-auto mb-8 max-w-2xl font-light text-white md:mb-12 sm:text-xl dark:text-gray-400">
            Want to stay ahead with The Shop Business Inc.? Sign up today and
            explore all our features for free for 30 days. Be the first to
            experience our innovative tools and solutions designed to streamline
            your business operations.{" "}
          </p>
          <CtaButton text="Get started today!" width="large" isCenter={true}></CtaButton>
        </div>
      </div>
    </section>
  );
}
