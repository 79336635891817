import React, { useState, Fragment } from "react";
import "./App.css";
import ScrollToTop from "react-scroll-to-top";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";

import toast, { Toaster } from "react-hot-toast";
import Header from "./components/header/header";
import Pricing from "./components/pricing/pricing";
import Footer from "./components/footer/footer";
import InfoSection from "./components/sections/sections";
import FeatureSection from "./components/feature.section";
import About from "./components/about";
import Cta from "./components/cta";
import NewsLetter from "./components/newsLetter";
import Stats from "./components/stat";
import CtaButton from "./components/cta-button";
import Modal from "./components/modal";
import EmailTemplate from "./components/email-template";

const navigation = [
  { name: "Why WorkWell ?", href: "#" },
  { key: "features", name: "Features", href: "#" },
  { name: "Pricing", href: "#" },
  { name: "About", href: "#" },
];
// Streamline time and attendance tracking
const featuresTitle = {
  Perks: {
    Title: "Streamline Employee Management",
    Description: `Efficiently manage employee onboarding, and exit procedures through a single platform`,
  },
  TimeManagement: {
    Title: "Operational Excellence",
    Description: `Achieve maximum efficiency and customer satisfaction with our Operational Excellence features. Designed to optimize every aspect of your business operations,`,
  },
  karma: {
    Title: "Insights and Analytics",
    Description: `Designed to provide clear, actionable insights, our system empowers you to make informed decisions and optimize your operations.`,
  },
  CoreHr: {
    Title: "Centralized Command",
    Description: `Designed for seamless integration and comprehensive oversight, our system ensures that you have full control over every aspect of your shop from a single, user-friendly interface.`,
  },
};

const Karmafeatures = [
  {
    name: "Basic Analytics",
    description:
      "Gain a deeper understanding of your business performance with our basic analytics tools. Track key metrics, identify trends, and measure success to continually improve your operations and strategies",
    icon: CheckBadgeIcon,
  },
  {
    name: "Dashboard Overview",
    description:
      "Stay on top of your business with a comprehensive dashboard that provides a clear, real-time overview of your operations. Access essential data and insights at a glance, enabling you to make quick, informed decisions.",
    icon: CheckBadgeIcon,
  },
  // {
  //   name: "Celebrate success with Karma Rewards",
  //   description:
  //     "When employees reach their targets on time or ahead of schedule, they can earn Karma Points and unlock special rewards and recognition.",
  //   icon: CheckBadgeIcon,
  // },
];
const CoreHrFeatures = [
  {
    name: "Centralized Management Panel",
    description:
      "This powerful tool allows you to access all necessary functions and tools from one seamless interface, ensuring efficient coordination and streamlined control over all aspects of your shop. From employee management to order processing, everything is at your fingertips, making it easier than ever to run your business smoothly.",
    icon: CheckBadgeIcon,
  },
  {
    name: "Real-Time Updates",
    description:
      "Stay informed with real-time updates on your business activities. Our system provides instant notifications and alerts, ensuring you are always aware of important changes and can respond promptly to any issues.",
    icon: CheckBadgeIcon,
  },
  // {
  //   name: "Seamlessly handle employee departures",
  //   description:
  //     "Smoothly handle employee departures with our exit management solution - streamline offboarding and ensure a smooth transition",
  //   icon: CheckBadgeIcon,
  // },
];
const TimeManagementfeatures = [
  {
    name: "Table Management",
    description:
      "Optimize your booking and ensure customer satisfaction with efficient table management, tailored to fit your specific business requirements.",
    icon: CheckBadgeIcon,
  },
  {
    name: "Order/Service Management",
    description:
      "Handle all orders and services with accuracy and speed. Our system ensures that no detail is missed, providing a flawless customer experience.",
    icon: CheckBadgeIcon,
  },
  {
    name: "Menu Management",
    description:
      "Easily update and adjust your offerings. Our system allows you to manage your menu or service listings with flexibility, meeting customer demands promptly.",
    icon: CheckBadgeIcon,
  },
];
const Perksfeatures = [
  {
    name: "Onboarding and Offboarding",
    description:
      "Effortlessly manage the complete lifecycle of your employees, from hiring to departure, ensuring smooth transitions and minimal disruptions.",
    icon: CheckBadgeIcon,
  },
  {
    name: "Employee Management.",
    description:
      "Keep all employee records organized with comprehensive management tools. Track roles, performance, and maintain a happy and productive workforce. ",
    icon: CheckBadgeIcon,
  },
  {
    name: "Leave Management",
    description:
      "Simplify leave requests and approvals. Keep track of employee availability and manage absences effortlessly to maintain operational efficiency",
    icon: CheckBadgeIcon,
  },
];

export default function App() {
  const [open, setOpen] = useState(false);
  const handleData = (show) => {
    setOpen(show);
  };
  return (
    <div className="App">
      <div className="bg-white mx-auto ">
        <div className="relative isolate  banner-background">
          <div
            style={{
              background: "url(https://storyset.com/images/stars.svg)",
            }}
          >
            <Header show={open} sendData={handleData}></Header>

            <div className="mx-auto max-w-2xl px-4  py-32 sm:py-48 lg:py-56">
              <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                  Elevate Your Business with Us
                </h1>
                <p className="mt-6 text-lg leading-8 text-white">
                  {/* Workwell empowers companies to retain and motivate their talent
                with unmatched efficiency. Save time and effort by managing all
                your employee benefits, perks, rewards and time off requests
                from a single, user-friendly platform. */}
                  {/* Our comprehensive platform streamlines every aspect of your
                  business operations, from sales to employee management and
                  order management. */}
                  Our comprehensive platform streamlines every aspect of your
                  business operations, from sales to employee management and
                  order management.
                </p>

                <CtaButton
                  text="Get started today!"
                  width="large"
                  isCenter={true}
                ></CtaButton>
              </div>
            </div>
          </div>
        </div>
        <FeatureSection></FeatureSection>
        {/* <Cta></Cta> */}

        <div className="overflow-hidden bg-white py-16 sm:py-16">
          <div id="perksfeatures">
            <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 lg:px-6">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {featuresTitle.Perks.Title}
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    {featuresTitle.Perks.Description}
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {Perksfeatures.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <img
                src={"./Time management-cuate.svg"}
                alt="Product screenshot"
              />
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white  py-16 sm:py-16">
          <div id="timemanagement">
            <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2  lg:px-6">
              <div className="lg:pr-8 lg:pt-4">
                <img
                  src={"./Organizing projects-cuate.svg"}
                  alt="Product screenshot"
                />
              </div>

              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {featuresTitle.TimeManagement.Title}
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    {featuresTitle.TimeManagement.Description}
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {TimeManagementfeatures.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-hidden bg-white  py-16 sm:py-16">
          <div id="karma">
            <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 lg:px-6">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {featuresTitle.karma.Title}
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    {featuresTitle.karma.Description}
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {Karmafeatures.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <img src={"./Visual data-cuate(1).svg"} alt="Product screenshot" />
            </div>
          </div>
        </div>
        <div className="overflow-hidden bg-white  py-16 sm:py-16">
          <div id="corehr">
            <div className="gap-8 items-center  px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 lg:px-6">
              <img
                src={"./Dark analytics-cuate.svg"}
                alt="Product screenshot"
              />
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {featuresTitle.CoreHr.Title}
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    {featuresTitle.CoreHr.Description}
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {CoreHrFeatures.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Stats show={open} sendData={handleData}></Stats> */}
        <Pricing show={open} sendData={handleData}></Pricing>
        {/* <About></About> */}
        <NewsLetter></NewsLetter>
        <Footer></Footer>
        {/* <EmailTemplate></EmailTemplate> */}
      </div>
      <Toaster />
      {/* Hiding for now
      <ScrollToTop
        smooth
        component={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
            />
          </svg>
        }
      /> */}
      <Modal show={open} sendData={handleData}></Modal>
    </div>
  );
}

//TODO: stats content https://flowbite.com/blocks/marketing/social-proof/
