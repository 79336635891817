import React, { useState, Fragment, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { getRandomInt, validEmailRegex } from "./util";
import { postEmailList } from "./axios.http";

type buttonWidth = "small" | "medium" | "large" | "full";
enum widthSize {
  small = "w-1/4",
  medium = "w-2/4",
  large = "w-3/4",
  full = "w-4/4 ",
}

export default function CtaButton({
  text = "Get Started",
  width = "full",
  isCenter = false,
}) {
  const [email, setEmail] = useState("");
  const [buttonWidth, setButtonWidth] = useState(null);
  const tailwindWidth = `relative w-full sm:${widthSize[width]} lg:${widthSize[width]}`;

  const handleChange = (event) => {
    setEmail((event.target as any).value);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = () => {
    if (!email.match(validEmailRegex)) {
      toast.error("Please enter a valid email address!");
    } else {
      setIsLoading(true);
      postEmailList(
        { id: getRandomInt(), email: email, package: "free" },
        () => {
          setIsLoading(false);
          setEmail("");
        }
      );
    }
  };
  useEffect(() => {
    const button: any = document.getElementById("wwButton");
    const buttonWidth = button?.offsetWidth;
    if (buttonWidth) {
      setButtonWidth(buttonWidth);
    }
  }, []);

  return (
    <div className="mt-10  items-center justify-center ">
      <div className={isCenter && "flex justify-center"}>
        <div className={tailwindWidth}>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            {/* <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
            </svg> */}
          </div>
          {/* <input
            type="search"
            id="search"
            value={email}
            className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter your email"
            required
            onChange={handleChange}
          /> */}
          {/* <a
            id="wwButton"
            style={{ minWidth: buttonWidth ?? `${buttonWidth}px` }}
            className={`
            text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 
             min-w-min` }
             href="https://app.theshopbusiness.com/signup"
       
             target="_blank">
        
              {text}
          </a> */}


          <a
              href="https://app.theshopbusiness.com/signup"
              target="_blank"
                className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
              >
     {text} 
              </a>

        </div>
      </div>
    </div>
  );
}
